.main {
  display: flex;
  min-height: 100vh;
  padding-top: 60px;
  background-color: var(--second-white);
}

.landing-sidebar {
  color: var(--black) !important;
  background-color: var(--white) !important;
}

.general-sidebar {
  color: var(--text-color) !important;
  background-color: var(--background) !important;
}

.errorContent {
  display: flex;
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  color: var(--text-color);
  height: calc(100vh - 60px);

  img {
    margin: 0 auto;
    max-width: 270px;
    aspect-ratio: 1/1;

    @media screen and (min-width: 641px) {
      max-width: 450px;
    }
  }

  h2 {
    text-transform: none;
    font-size: var(--fs-2xl);
    font-weight: var(--fw-bold);

    @media screen and (min-width: 641px) {
      font-size: var(--fs-3xl);
    }
    @media screen and (min-width: 941px) {
      font-size: var(--fs-4xl);
    }
    @media screen and (min-width: 1501px) {
      font-size: var(--fs-5xl);
    }
  }

  a {
    color: gray;
    font-size: var(--fs-sm);

    @media screen and (min-width: 641px) {
      font-size: var(--fs-md);

      &:hover {
        font-size: 110%;
      }
    }
  }
}
